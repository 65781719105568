
              @import "@/assets/css/variables.scss";
            

















































































































































































































































@import '@/assets/css/pages/openAccount.scss';

/deep/ .el-steps {
  .el-step:nth-child(1) {
    flex-basis: 165px !important;
  }

  .el-step:nth-child(2) {
    flex-basis: 160px !important;
  }

  .el-step:nth-child(3) {
    flex-basis: 275px !important;
  }

  .el-step:nth-child(4) {
    flex-basis: 145px !important;
  }
  .el-step:nth-child(5) {
    flex-basis: calc(100% - 165px - 165px - 270px - 140px) !important;
  }
}

@media (max-width: 767px) {
  /deep/ .el-steps {
    .el-step:nth-child(1) {
      flex-basis: 160px !important;
    }

    .el-step:nth-child(2) {
      flex-basis: 270px !important;
      // flex-basis: 240px !important;
    }

    .el-step:nth-child(3) {
      flex-basis: 360px !important;
      // flex-basis: 240px !important;
    }

    .el-step:nth-child(4) {
      flex-basis: 240px !important;
      // flex-basis: 240px !important;
    }
    .el-step:nth-child(5) {
      flex-basis: calc(100% - 160px - 270px - 360px - 240px) !important;
    }
  }
}

@media (max-width: 400px) {
  /deep/ .el-steps {
    .el-step:nth-child(1) {
      flex-basis: 190px !important;
    }

    .el-step:nth-child(2) {
      flex-basis: 270px !important;
      // flex-basis: 240px !important;
    }

    .el-step:nth-child(3) {
      flex-basis: 560px !important;
      // flex-basis: 240px !important;
    }

    .el-step:nth-child(4) {
      flex-basis: 300px !important;
      // flex-basis: 240px !important;
    }
    .el-step:nth-child(5) {
      flex-basis: calc(100% - 190px - 270px - 560px - 300px) !important;
    }
  }
}
